
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { ScrollComponent } from "../../../assets/ts/components";
import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useI18n } from "vue-i18n";
import { TenantUsersSessionListModel } from "@/domain/hotel-management/tenant-users-session-list/model/TenantUsersSessionListModel";
import i18n from "@/presentation/language/i18n";

interface KTMessage {
  type: string;
  name?: string;
  image: string;
  time: string;
  text: string;
  emotionAnalysis?: Object;
}

export default defineComponent({
  name: "private-chat",
  components: {
    MessageIn,
    MessageOut,
  },
  setup(methods) {
    const { t, te } = useI18n();
    const store = useStore();
    const route = useRoute();

    const staffId = ref<any>(Number(route.params["staffId"]));
    const sessionId = ref<any>(Number(route.params["sessionId"]));

    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();

    const messages = ref<Array<KTMessage>>([]);
    const sessionTitle = ref<string | undefined>();
    const sessionList = ref<SessionListModel[]>([]);

      const tenantUsersSessionListModel: TenantUsersSessionListModel = {
      staffId: Number(route.params["staffId"]),
      session: {
        productSessionMessages: []
      },
      page: 1,
      pageSize: 50,
    };

    const sessionController = store.state.ControllersModule.sessionController;

    const newMessageText = ref("");

    watch(
      () => route.params["sessionId"],
      (newValue, oldValue) => {
          if(newValue != oldValue)
          getSessionHistory();
      }
    );

    const getSessionList = () => {
      sessionList.value = [];
      messages.value = [];

      sessionController
        .tenantUsersSessionList(tenantUsersSessionListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((session) => {
              sessionList.value.push(session);
              if (session.session.id == sessionId.value) {
                sessionTitle.value = session.session.title
                session.session.productSessionMessages.forEach((log, index) => {
                  messages.value.push({
                    type: log.owner === "USER" ? "out" : "in",
                    name: log.owner === "USER" ? undefined : "Asistan",
                    image:
                      log.owner === "USER"
                        ? "/media/avatars/blank.png"
                        : "/media/avatars/ai.jpg",
                    time: formatDate(log.createdAt),
                    text: log.text,
                    emotionAnalysis: log.emotionAnalysis
                  });
                });
              }
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getSessionHistory = () => {
      messages.value = [];

      sessionList.value.forEach((session) => {
              if (session.session.id == Number(route.params["sessionId"])) {
                sessionTitle.value = session.session.title
                if(session.session.productSessionMessages)
                session.session.productSessionMessages.forEach((log, index) => {
                  messages.value.push({
                    type: log.owner === "USER" ? "out" : "in",
                    name: log.owner === "USER" ? undefined : "Asistan",
                    image:
                      log.owner === "USER"
                        ? "/media/avatars/blank.png"
                        : "/media/avatars/ai.jpg",
                    time: formatDate(log.createdAt),
                    text: log.text,
                    emotionAnalysis: log.emotionAnalysis
                  });
                });
              }
            });
    }

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const now = new Date();

      const difference = now.getTime() - date.getTime();

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));

      if (days === 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        if (hours === 0) {
          const minutes = Math.floor(difference / (1000 * 60));
          return `${minutes} ` + i18n.global.t("common.times.minuteAgo");
        } else {
          return `${hours} ` + i18n.global.t("common.times.hourAgo");
        }
      } else {
        return `${days} ` + i18n.global.t("common.times.dayAgo");
      }
    };

    onMounted(() => {
      getSessionList();
      setCurrentPageTitle(t("modals.hotel.title"));

      if (!messagesRef.value) {
        return;
      }

      ScrollComponent.bootstrap();
    });

    return {
      formatDate,
      messages,
      messagesRef,
      newMessageText,
      messagesInRef,
      messagesOutRef,
      sessionList,
      sessionTitle,
    };
  },
});
